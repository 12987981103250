import Footer from '../../component/Footer'
import { Intro } from './Components/Intro'
import { Menu } from 'component/Menu'
import * as React from 'react'
import Starfield from './Components/Starfield'

const HomePage = () => {
  return (
    <>
      <div>
        <Starfield />
      </div>

      <div className="container">
        <Footer />
        <Menu />
        <Intro />
      </div>
    </>
  )
}

export default HomePage
