// Starfield.js
import React from 'react';
import '../../../Stars.scss'; // Asegúrate de importar el archivo SCSS

const Starfield = () => {
  return (
    <div className="starfield">
      {Array.from({ length: 100 }).map((_, index) => (
        <div key={index} className={`star star-${index + 1}`}></div>
      ))}
    </div>
  );
};

export default Starfield;