import React from 'react'

import TechFooter from '../../../component/TechFooter'
export const Intro = () => {
  return (
    <>
      <section className="row mt-5 align-items-center">
        <div className="col-md-6 col-sm-12 ">
          <h2>Desarrollo de software a medida</h2>
          <small className="mt-1">
            El desarrollo de software como motor de tu éxito empresarial
          </small>
          <p className="mt-2">
            ¿Necesitas una solución personalizada para tu negocio? En UmbraCorp
            SPA, nos especializamos en el desarrollo de software a medida para
            satisfacer las necesidades únicas de cada cliente.
            <br /> Con nuestro equipo de expertos en tecnología, podemos crear
            soluciones innovadoras y eficientes que mejorarán la productividad y
            el rendimiento de tu empresa. ¡Contáctanos hoy para comenzar a
            construir la solución perfecta para tu negocio!
          </p>

          <div>+ 700 proyectos finalizados</div>
          <div>+ 300 empresas automatizadas</div>
          <TechFooter />
        </div>
        <div className="col-md-6 col-sm-12">
          <img src="/images/intro.jpg" className="intro-img" alt="banner" />
        </div>
      </section>

      <section className="row mt-5 align-items-center">
        <div className="col-md-6 col-sm-12 order-md-last ">
          <h2>Sistemas estadísticos</h2>

          <small className="mt-1">Optimiza tu negocio con datos precisos</small>
          <p className="mt-2">
            ¿Te gustaría tener acceso en tiempo real a la información clave de
            tu negocio? Un sistema de dashboard que muestra tus KPI te permite
            visualizar el rendimiento de tu empresa en un solo lugar. Toma
            decisiones más rápidas y basadas en datos confiables, optimiza
            procesos y mejora tus resultados con un control total. ¡Descubre
            cómo transformar tus datos en oportunidades!
          </p>

          <div>+ 700 proyectos finalizados</div>
          <div>+ 300 empresas automatizadas</div>
        </div>
        <div className="col-md-6 col-sm-12 ">
          <img src="/images/dashboard.jpg" className="intro-img" alt="banner" />
        </div>
      </section>

      <section className="row mt-5 align-items-center">
        <div className="col-md-6 col-sm-12 ">
          <h2>Automatización RPA</h2>

          <small className="mt-1">Ahorra tiempo y mejora la eficiencia</small>
          <p className="mt-2">
            Optimiza tus procesos repetitivos con la automatización RPA (Robotic
            Process Automation). Libera a tu equipo de tareas manuales y reduce
            errores, aumentando la productividad y precisión en todas tus
            operaciones. Automatiza flujos de trabajo complejos y enfócate en lo
            que realmente importa: hacer crecer tu negocio de manera eficiente.
            ¡Empieza hoy a transformar tu empresa!n
          </p>

          <div>+ 700 proyectos finalizados</div>
          <div>+ 300 empresas automatizadas</div>
        </div>
        <div className="col-md-6 col-sm-12">
          <img src="/images/rpa.jpg" className="intro-img" alt="banner" />
        </div>
      </section>
      <section className="row mt-5 align-items-center">
        <div className="col-md-6 col-sm-12 ">
          <h2>Integración</h2>

          <small className="mt-1">Conecta y optimiza tus sistemas</small>
          <p className="mt-2">
            Impulsa tu negocio con nuestras soluciones de integración de APIs y
            sistemas. Conectamos plataformas, aplicaciones y herramientas para
            optimizar tus procesos, mejorar la eficiencia y garantizar una
            comunicación fluida entre tus sistemas. Ahorra tiempo y recursos
            automatizando tareas críticas, mientras nosotros nos encargamos de
            la integración tecnológica. ¡Lleva tu empresa al siguiente nivel con
            nuestro expertise!
          </p>

          <div>+ 700 proyectos finalizados</div>
          <div>+ 300 empresas automatizadas</div>
        </div>
        <div className="col-md-6 col-sm-12 order-md-first">
          <img
            src="/images/integration.jpg"
            className="intro-img"
            alt="banner"
          />
        </div>
      </section>
      <section className="row mt-5">
        <h2>Nuestros clientes</h2>
        <div
          className="p-4 bg-white "
          style={{
            marginTop: 15,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 40,
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <img src="/images/logo_dercomaq.png" alt="banner" />
          <img src="/images/logo_newoffice.png" alt="banner" />
          <img src="/images/logo-hbimp.png" alt="banner" />
          <img src="/images/logo-hbled.png" alt="banner" />
          <img src="/images/logoBuc.png" alt="banner" />
          <img src="/images/logococacola.png" alt="banner" />
          <img src="/images/logokarcher.png" alt="banner" />
          <img src="/images/logosteward.png" alt="banner" />
        </div>
      </section>
    </>
  )
}
